<template>
  <v-container fluid>
    <v-row>
      <v-col
        class="grow"
        v-show="
          !$route.params.cmd &&
          !$route.params.pfm &&
          !$route.params.dmd &&
          !$route.params.facture &&
          !$route.params.tier
        "
      >
        <listitemspage
          :selitem="selitem"
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :qDelete="Qdelete"
          :PageResponse="'products'"
          :AllResponse="'allproducts'"
          :title="'Liste des Produits'"
          :headers="headers"
          :field="'CODE'"
          :filename="'Produits'"
          :sheetname="'Produits'"
          :showedit="false"
          :Kind="parseInt(prd_type)"
          :CatScope="categories"
          :VarScope="template"
          :AttScope="attributs"
          :Service="services"
          :Type="types"
          :btn1="{ text: 'Filtre Avancé', icon: 'mdi-tune' }"
          :btn2="unfilter ? { text: '', icon: 'mdi-filter-remove' } : {}"
          :dup="true"
          @duplicate="duplicate"
          @click1="OpenFilterForm"
          @click2="unfiltrer"
          @open="OpenProductForm"
          @rowselect="ProductChange"
          @hrf="ShowList"
          :del_disable="
            product.cmddetailcount > 0 ||
            product.pfmdetailcount > 0 ||
            product.mvmdetailcount > 0 ||
            product.dernier_inv != null ||
            product.id == null
          "
          :Add="editer"
          :del="(editer && product.statut_id == 1) || valider"
          :list_options="technique ? true : false"
          :key="klist"
          :search_elm="filtre"
          :load="loading"
        >
        </listitemspage>
      </v-col>
    </v-row>
    <productform
      :item="product"
      :key="pf"
      :showForm="!isProductClosed"
      @close="closeProductForm"
      @gamme="pf++"
      @file_changed="klist++"
      :templates="templates"
      :attributes="attributes"
      :values_list="values_list"
      :banks="banks"
      :countrys="countrys"
      :cat_tier="cat_tier"
      :cat_product="cat_product"
      :cat_familles="cat_familles"
      :barcodetypes="barcodetypes"
      :plancomptable="plancomptable"
      :depots="depots"
      :tiers_types="tiers_types"
      :units="units"
      :prd_type="prd_type"
      :editer="editer"
      :technique="technique"
      :valider="valider"
      :boms="boms"
      :status_list="status_list"
      :allarticles="allarticles"
      :route="route"
    >
    </productform>
    <filterform
      :showForm="!isFilterClosed"
      :categories_list="cat_product"
      :templates_list="templates"
      :attributes="attributes"
      :values_list="values_list"
      @close="closeFilterForm"
    >
    </filterform>
    <formdetail
      :key="kd"
      :showform="!isDetailClose"
      :doc="doc"
      @close="CloseDetail"
      @open="OpenDetail"
    >
    </formdetail>
    <formlist
      :title="title"
      :list="list"
      :showform="!isListClose"
      :listheaders="listheaders"
      :key="fl"
      @close="closeListForm"
      :width="width"
      :Total="true"
      :master="true"
      :btn_save="btn_save"
      :btn_save_dis="btn_save_dis"
      @open="OpenDetail"
      :imp_excel="imp_excel"
      :laoding="loading"
    >
    </formlist>
    <productassist
      :item="product"
      :showForm="!isProductAssitClose"
      :key="kpa"
      :list_modeles="templates_list"
      :attributs="attributes"
      :values_list="values_list"
      @close="closeProductAssist"
      :route="route"
      :prd_type="prd_type"
      :boms="boms"
      :templates="templates"
    >
    </productassist>
    <productassistduplicate
      :item="product"
      :showForm="!isProductAssitDuplicateClose"
      :key="kpad"
      :list_modeles="templates"
      :attributs="attributes"
      :product="selitem"
      :values_list="values_list"
      @close="closeProductAssistDuplicate"
      :route="route"
      :prd_type="prd_type"
      :selected_model="selected_model"
      :boms="boms"
      :units="units"
      :templates="templates"
    >
    </productassistduplicate>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import PRODUCTS from "../graphql/Product/PRODUCTS.gql";
import PRODUCT from "../graphql/Product/PRODUCT.gql";
import ALLPRODUCTS from "../graphql/Product/ALLPRODUCTS.gql";
import DELETE_PRODUCT from "../graphql/Product/DELETE_PRODUCT.gql";
import TOTALS from "../graphql/Product/TOTALS.gql";
import CMD_CLIENT from "../graphql/Product/CMD_CLIENT.gql";
import CMD_FOURNIS from "../graphql/Product/CMD_FOURNIS.gql";
import CMD_INTERNE from "../graphql/Product/CMD_INTERNE.gql";
import MVMS from "../graphql/Product/MVMS.gql";
import DATA from "../graphql/Product/DATA.gql";

//import ARTICLES from "../graphql/Commande/ARTICLES.gql";
import { spec, pc, bag, image_prod } from "print/data.js";

export default {
  components: {
    listitemspage: () => import("../components/ListItemsPages.vue"),
    productform: () => import("../views/ProductForm.vue"),
    filterform: () => import("../components/FilterForm.vue"),
    formlist: () => import("../components/FormList.vue"),
    formdetail: () => import("../components/FormDetail.vue"),
    productassist: () => import("../components/ProductAssist.vue"),
    productassistduplicate: () =>
      import("../components/ProductAssistDupli.vue"),
  },
  props: {
    prd_type: String,
  },
  data: () => ({
    selected_model: {},
    isProductAssitDuplicateClose: true,
    filtre: "",
    selitem: {},
    btn_save_dis: true,
    loading: false,
    imp_excel: false,
    btn_save: false,
    editer: false,
    technique: false,
    valider: false,
    doc_type: 1,
    width: "1000px",
    unfilter: false,
    pf: 0,
    vf: 100,
    kpa: 800,
    kpad: 2000,
    template: 0,
    categories: [],
    attributs: [],
    services: [],
    types: [],
    status_list: [],
    allarticles: [],

    headers: [
      {
        text: "Code",
        value: "code",
        enum: "CODE",
        hoover_list: "attributs",
        hoover_name: "name",
        hoover_value: "value",
        selected: true,
        width: "auto",
        index: 0,
      },
      {
        text: "Aperçu",
        value: "preview_file",
        enum: "PREVIEW_FILE",
        align: "left",
        slot: "upload/download",
        upload: false,
        download: true,
        preview: true,
        delete: false,
        sh_upload: false,
        sh_delete: false,
        update_query: null,
        sortable: false,
        selected: true,
        width: 5,
        index: 1,
      },
      {
        text: "Ref.",
        value: "ref",
        enum: "REF",
        selected: true,
        index: 2,
      },
      {
        text: "Libelle",
        align: "start",
        value: "label",
        enum: "LABEL",
        slot: "href",
        selected: true,
        index: 3,
      },

      {
        text: "Nature",
        value: "nature",
        sortable: false,
        enum: "NATURE",
        list: ["P", "S"],
        align: "left",
        selected: false,
        index: 4,
      },
      {
        text: "Client",
        value: "client_name",
        enum: "CLIENT_NAME",
        hiden: false,
        selected: true,
        index: 5,
      },

      {
        text: "Stock Ini.",
        value: "stock_ini",
        align: "end",
        slot: "cur",
        hiden: true,
        sortable: false,
        selected: true,
        index: 6,
      },
      {
        text: "Variant",
        value: "variante",
        enum: "VARIANTE",
        list: ["Oui", "Non"],
        sortable: false,
        selected: false,
        index: 7,
      },

      {
        text: "Reapros?",
        value: "reapros",
        enum: "REAPROS",
        list: ["Oui", "Non"],
        selected: false,
        index: 8,
      },
      {
        text: "Statut",
        value: "statuts_name",
        enum: "STATUTS_NAME",
        list: [
          "Att. BAG",
          "Att. Conf. BAG",
          "Att. Trait. Reserves",
          "Att. Validation",
          "Att. Recette",
          "Actif",
          "Archivé",
          "Obsolète",
        ],
        selected: true,
        slot: "chip",
        color: "color",
        index: 9,
      },
      {
        text: "Image Produit",
        value: "url_date",
        align: "center",
        slot: "date",
        enum: "URL_DATE",
        icon: "mdi-close",
        selected: true,
        hiden: true,
        index: 10,
      },
      {
        text: "BAG",
        value: "bag_date",
        align: "center",
        slot: "date",
        enum: "BAG_DATE",
        icon: "mdi-close",
        selected: false,
        index: 11,
      },
      {
        text: "Email BAG",
        value: "bag_send_date",
        align: "center",
        slot: "date",
        enum: "BAG_SEND_DATE",
        icon: "mdi-close",
        selected: false,
        index: 12,
      },
      {
        text: "BAG Confirm",
        value: "bag_confirm_date",
        align: "center",
        slot: "date",
        enum: "BAG_CONFIRM_DATE",
        icon: "mdi-close",
        selected: false,
      },
      {
        text: "Date de Création",
        value: "created_at",
        enum: "CREATED_AT",
        slot: "date",
        selected: true,
        index: 10,
      },
    ],

    Qselect: {},
    Qselectall: {},
    Qdelete: {},
    Qdetaildelete: {},
    isProductClosed: true,
    isFilterClosed: true,
    isProductAssitClose: true,
    product: {},
    klist: 0,
    kd: 100,
    fl: 200,
    title: "",
    isListClose: true,
    isDetailClose: true,
    list: [],
    listheaders: [],
    bag_true_list: [
      "Att. Validation",
      "Actif",
      "A surveiller",
      "Bloqué",
      "Att. Recette",
      "Att. BAG",
      "Att. Conf. BAG",
      "Att. Trait. Reserves",
    ],
    bag_false_list: [
      "Att. Validation",
      "Actif",
      "A surveiller",
      "Bloqué",
      "Att. Recette",
    ],
    cmdheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },

      {
        text: "Reference",
        value: "nocmd",
        slot: "href",
        selected: true,
      },
      {
        text: "Date commande",
        value: "date_cmd",
        selected: true,
      },
      {
        text: "Client",
        value: "tier_name",
        selected: true,
      },
      {
        text: "Commande",
        value: "cmd_client",
        align: "end",
        slot: "cur22",
        total: "cmd_client",
        selected: true,
      },
      {
        text: "Livrée",
        value: "qte_liv",
        align: "end",
        slot: "cur22",
        selected: true,
        total: "qte_liv",
      },
      {
        text: "Retour",
        value: "qte_ret",
        align: "end",
        slot: "cur22",
        selected: true,
        total: "qte_ret",
      },
      {
        text: "a Livrer",
        value: "reste",
        align: "end",
        slot: "cur22",
        selected: true,
        total: "reste",
      },
    ],
    detailsheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },

      {
        text: "Reference",
        value: "nobon",
        slot: "href",
        selected: true,
      },
      {
        text: "Type",
        value: "type_opr",
        selected: true,
      },

      {
        text: "Date mouvement",
        value: "mvm_date",
        slot: "date",
        selected: true,
      },
      {
        text: "Tier",
        value: "tier_name",
        selected: true,
      },
      {
        text: "Depot",
        value: "depot_name",
        selected: true,
      },
      {
        text: "Qte",
        value: "qte_mvm",
        align: "end",
        slot: "cur22",
        total: "qte_signe",
        selected: true,
      },

      {
        text: "Valeur",
        value: "total",
        align: "end",
        slot: "cur22",
        selected: true,
      },
    ],
    route: {},
    doc: {},
    templates: [],
    attributes: [],
    values_list: [],
    banks: [],
    boms: [],
    countrys: [],
    cat_tier: [],
    cat_product: [],
    cat_familles: [],
    barcodetypes: [],
    plancomptable: [],
    tiers_types: [],
    units: [],
    templates_list: [],

    articles_list: [],
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
  }),

  computed: {
    pc() {
      return pc;
    },
    depots() {
      return this.$store.state.depots.filter((elm) => elm.ChildCount == 0);
    },

    Totals() {
      return TOTALS;
    },
  },
  watch: {
    prd_type: {
      handler() {
        this.get_data();
        this.klist;
      },
    },
    "$store.state.filtre": {
      handler() {
        if (this.$store.state.filtre) {
          this.filtre = this.$store.state.filtre.filter_by;
          this.selitem = this.$store.state.filtre.obj;
          this.klist++;
        }
      },
    },
  },
  created() {
    if (this.$route.params.filtre) {
      this.filtre = this.$route.params.filtre;
      this.selitem = this.$route.params.obj;
      this.klist++;
    }
  },

  async mounted() {
    this.Qdelete = DELETE_PRODUCT;
    this.Qselect = PRODUCTS;
    this.Qselectall = ALLPRODUCTS;

    this.editer =
      (this.prd_type == "02" && this.$store.state.auth.includes("02002")) ||
      (this.prd_type == "03" && this.$store.state.auth.includes("02003")) ||
      (this.prd_type == "04" && this.$store.state.auth.includes("02004")) ||
      (this.prd_type == "34" && this.$store.state.auth.includes("02034")) ||
      this.$store.state.isadmin;
    this.valider =
      (this.prd_type == "02" && this.$store.state.auth.includes("03002")) ||
      (this.prd_type == "03" && this.$store.state.auth.includes("03003")) ||
      (this.prd_type == "04" && this.$store.state.auth.includes("03004")) ||
      (this.prd_type == "34" && this.$store.state.auth.includes("03034")) ||
      this.$store.state.isadmin;
    this.technique =
      (this.prd_type == "03" && this.$store.state.auth.includes("06003")) ||
      this.$store.state.isadmin;

    await this.get_data();
    if (this.$route.params.tier) {
      if (this.$route.params.item.id > 0) {
        this.product.id = this.$route.params.item.id;
        this.OpenProductForm(this.product);
      }
    }
    if (
      this.$route.params.cmd ||
      this.$route.params.mvm ||
      this.$route.params.facture ||
      this.$route.params.dmd
    ) {
      if (this.$route.params.item.produit_id > 0) {
        this.product.id = this.$route.params.item.produit_id;
        this.OpenProductForm(this.product);
      }
    }

    if (this.$route.params.pfm) {
      if (this.$route.params.item.produit_id > 0) {
        this.product.id = this.$route.params.item.produit_id;
        this.OpenProductForm(this.product);
      } else {
        this.product.id = -1;
        this.route.pfm = this.$route.params.pfm;
        this.route.route_name = this.$route.params.name;
        this.route.item_id = this.$route.params.item.id;
        this.product.label = this.$route.params.item.label;
        this.product.pu = this.$route.params.item.pu;
        this.product.comment = this.$route.params.item.comment;
        this.product.url = this.$route.params.item.url;
        this.product.spec_tech = this.$route.params.item.spec_tech;
        this.product.preview_file = this.$route.params.item.preview_file;
        this.product.fk_unit = this.$route.params.item.fk_unit;
        if (spec && this.$route.params.pfm.tier_id) {
          this.route.client_id = this.$route.params.pfm.tier_id;
          this.product.client_id = this.$route.params.item.tier_id;
        }
        this.$nextTick(() => {
          this.isProductAssitClose = false;
          this.kpa++;
        });
      }
    }
  },

  methods: {
    // product_add(item) {
    //   this.product = item;
    //   this.selitem = item;
    //   // this.isProductClosed = false;
    //   // this.pf++;
    // },
    async closeProductAssist(item) {
      if (item)
        if (item.id) {
          if (item.id > 0) {
            let r = await this.requette(
              PRODUCT,
              {
                produit_id: item.id,
              },
              "no-cache"
            );
            if (r) {
              this.product = r.product[0];
            }
          } else this.product.id = -1;
          if (this.$route.params.pfm) {
            this.route.pfm = this.$route.params.pfm;
            this.route.route_name = this.$route.params.name;
            if (this.$route.params.item)
              this.route.item_id = this.$route.params.item.id;
            if (this.product.id == -1) {
              this.product.label = this.$route.params.item.label;
              this.product.fk_unit = this.$route.params.item.fk_unit;
              if (spec && this.$route.params.pfm.tier_id) {
                this.product.client_id = this.$route.params.pfm.tier_id;
              }
              this.product.statut_id = "1";
              if (this.prd_type == "03")
                this.product.prixvente = this.$route.params.item.pu;
              this.product.categories = [];
              this.product.docs = [];
              this.product.tier_type_prix = [];
              this.product.tabcolisattributs = [];
              this.product.qte_prix = [];
              this.product.boms = [];
              this.product.articles = [];
              this.product.cmddetailcount = 0;
            }
          }
          this.isProductClosed = false;
          this.pf++;
        }
      this.isProductAssitClose = true;
    },
    async closeProductAssistDuplicate(item) {
      if (item) {
        let r = await this.requette(
          PRODUCT,
          {
            produit_id: item.id,
          },
          "no-cache"
        );
        if (r) {
          this.product = r.product[0];
        }
      }
      this.isProductAssitDuplicateClose = true;
    },
    async get_data() {
      this.headers[5].hiden =
        !spec || this.prd_type == "02" || this.prd_type == "34";
      this.headers[9].list = bag ? this.bag_true_list : this.bag_false_list;
      this.headers[10].hiden = this.prd_type == "02" || this.prd_type == "34";
      this.headers[10].text = image_prod;
      let r = await this.requette(DATA, { VarScope: 1 });
      if (r) {
        this.templates = r.alltemplates;
        this.templates_list = r.alltemplates.filter((elm) =>
          this.prd_type == "02" || this.prd_type == "34"
            ? elm.tobuy == 1
            : this.prd_type == "03"
            ? elm.tosell == 1
            : this.prd_type == "04"
            ? elm.tomanufacture == 1 || elm.tobuy == 1
            : false
        );
        this.attributes = r.allattributes;
        this.values_list = r.allvalues;
        this.status_list = r.statuts;
        this.banks = r.banks;
        this.countrys = r.countrys;
        this.cat_tier = r.categorie.filter((elm) => elm.type_cat == 1);
        this.cat_product = r.categorie.filter(
          (elm) => elm.type_cat == 2 || elm.type_cat == 3
        );
        this.cat_familles = r.categorie.filter((elm) => elm.type_cat == 5);
        this.barcodetypes = r.barcodetypes;
        this.plancomptable = r.plancomptable;
        this.units = r.units;
        this.boms = r.process_boms;
        this.tiers_types = r.tiertypes;
      }

      // if (this.prd_type == "04" && this.pc) {
      //   v = {
      //     service: 0,
      //     tomanufacture: 1,
      //   };
      //   r = await this.requette(ARTICLES, v);
      //   if (r) {
      //     this.allarticles = r.articles;
      //   }
      // }
    },
    async requette(query, v, f) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: f,
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    closeListForm() {
      this.isListClose = true;
    },
    OpenDetail(item) {
      item.doc_type = this.doc_type;
      if (this.doc_type == 2) item.document_id = item.cmd_id;
      else item.document_id = item.mvm_id;
      this.doc = item;
      this.kd++;
      this.isDetailClose = false;
    },
    CloseDetail() {
      this.isDetailClose = true;
      this.btn_save = false;
      this.imp_excel = false;
    },

    ShowList(item) {
      let query;
      let result;
      this.width = "1000px";
      switch (item.col) {
        case "stock_cmpt":
          this.doc_type = 3;
          this.title = "Mouvements -- Produit : " + item.label;
          this.listheaders = this.detailsheaders;
          query = MVMS;
          result = "product_mvms";
          this.width = "1400px";
          break;
        case "cmd_client":
          this.doc_type = 2;
          this.title = "Commandes En cours -- Produit : " + item.label;
          this.cmdheaders[3].hiden = false;
          this.cmdheaders[3].text = "Client";
          this.cmdheaders[4].value = "cmd_client";
          this.cmdheaders[4].total = "cmd_client";
          this.cmdheaders[5].text = "Livré";
          this.cmdheaders[7].text = "à Livrer";
          query = CMD_CLIENT;
          result = "cmds_clients";
          this.listheaders = this.cmdheaders;
          this.width = "1400px";
          break;
        case "cmd_interne":
          this.doc_type = 2;
          this.title = "Commandes Interne En cours -- Produit : " + item.label;
          this.cmdheaders[3].hiden = true;
          this.cmdheaders[6].hiden = true;
          this.cmdheaders[4].value = "cmd_interne";
          this.cmdheaders[4].total = "cmd_interne";
          this.cmdheaders[5].text = "Fabriqué";
          this.cmdheaders[7].text = "à Fabriquer";
          query = CMD_INTERNE;
          result = "cmds_interne";
          this.listheaders = this.cmdheaders;
          this.width = "1400px";
          break;
        case "cmd_fournisseur":
          this.doc_type = 2;
          this.title =
            "Commandes Fournisseurs En cours -- Produit : " + item.label;
          this.cmdheaders[3].hiden = false;
          this.cmdheaders[3].text = "Fournisseur";
          this.cmdheaders[4].value = "cmd_fournisseur";
          this.cmdheaders[4].total = "cmd_fournisseur";
          this.cmdheaders[5].text = "Receptionné";
          this.cmdheaders[7].text = "à Receptionner";
          query = CMD_FOURNIS;
          result = "cmds_fournis";
          this.listheaders = this.cmdheaders;
          this.width = "1400px";
          break;
        default:
          break;
      }
      if (
        (item.col == "cmd_client" && item.service == "1") ||
        item.service == "0"
      )
        this.$apollo
          .query({
            query: query,
            variables: {
              produit_id: item.id,
            },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            this.list = data.data[result];
            this.isListClose = false;
            this.fl++;
          })
          .catch((error) => {
            this.loading = false;
            this.snackbar_color = "error";
            this.snackbar_text = error.message;
            this.snackbar = true;
          });
    },

    async ProductChange(item) {
      this.product = item;

      if (this.$route.params.produit_id > 0) {
        this.route.route_name = this.$route.params.name;
        this.route.of_id = this.$route.params.of_id;
        this.route.produit_id = this.$route.params.produit_id;
      }
      this.selitem = item;
      //this.pf++;
    },
    async duplicate(item) {
      this.product = item;
      if (this.product.nblink == 0) {
        if (this.product.attributs.length > 0) {
          this.isProductAssitDuplicateClose = false;
          this.kpad++;
          //}
        } else {
          // this.product.id = -1;
          let r = await this.requette(
            PRODUCT,
            {
              produit_id: this.product.id,
            },
            "no-cache"
          );
          if (r) {
            this.product = r.product[0];
            this.product.id = -2;
            if (this.$route.params.pfm) {
              this.route.pfm = this.$route.params.pfm;
              this.route.route_name = this.$route.params.name;
              if (this.$route.params.item)
                this.route.item_id = this.$route.params.item.id;
              if (this.product.id == -1) {
                this.product.label = this.$route.params.item.label;
                this.product.fk_unit = this.$route.params.item.fk_unit;
                if (spec && this.$route.params.pfm.tier_id) {
                  this.product.client_id = this.$route.params.pfm.tier_id;
                }
                this.product.statut_id = "1";
                if (this.prd_type == "03")
                  this.product.prixvente = this.$route.params.item.pu;
                this.product.categories = [];
                this.product.docs = [];
                this.product.tier_type_prix = [];
                this.product.tabcolisattributs = [];
                this.product.qte_prix = [];
                this.product.gammes = [];
                this.product.articles = [];
                this.product.cmddetailcount = 0;
              }
            }
            this.isProductClosed = false;
            this.pf++;
          }
        }
      }
    },
    async OpenProductForm(item) {
      if (item.id < 0) {
        // verifier l'existance de modele avant a faire
        this.product = item;
        this.isProductAssitClose = false;
        this.kpa++;
      } else {
        let r = await this.requette(
          PRODUCT,
          {
            produit_id: item.id,
          },
          "no-cache"
        );
        if (r) {
          this.product = r.product[0];
          if (this.$route.params.pfm) {
            this.route.pfm = this.$route.params.pfm;
            this.route.route_name = this.$route.params.name;
            this.route.item_id = this.$route.params.item.id;
          }
          if (this.$route.params.cmd) {
            this.route.cmd = this.$route.params.cmd;
            this.route.route_name = this.$route.params.name;
            this.route.item_id = this.$route.params.item.id;
          }
          if (this.$route.params.tier) {
            this.route.tier = this.$route.params.tier;
            this.route.route_name = this.$route.params.name;
            this.route.item_id = this.$route.params.item.id;
          }
          if (this.$route.params.dmd) {
            this.route.dmd = this.$route.params.dmd;
            this.route.route_name = this.$route.params.name;
            this.route.item_id = this.$route.params.item.id;
          }
          if (this.$route.params.mvm) {
            this.route.mvm = this.$route.params.mvm;
            this.route.route_name = this.$route.params.name;
            this.route.item_id = this.$route.params.item.id;
          }
          if (this.$route.params.facture) {
            this.route.facture = this.$route.params.facture;
            this.route.route_name = this.$route.params.name;
            this.route.item_id = this.$route.params.item.id;
          }
          this.isProductClosed = false;
          this.pf++;
        }
      }
    },
    closeProductForm(item) {
      this.selitem = item;
      this.isProductClosed = true;
    },
    OpenFilterForm() {
      this.isFilterClosed = false;
    },
    closeFilterForm(cat, temp, att, service, types) {
      if (cat || temp || att) {
        this.unfilter = true;
        this.categories = cat;
        this.template = parseInt(temp);
        this.attributs = att;
        this.services = service;
        this.types = types;
        this.klist++;
      }
      this.isFilterClosed = true;
    },
    unfiltrer() {
      this.unfilter = false;
      this.categories = [];
      this.attributs = [];
      this.services = [];
      this.types = [];
      this.template = 0;
      this.klist++;
    },
  },
};
</script>
